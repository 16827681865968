*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: Jost-Light;
  src: url("./FontFamily/Jost-Light.ttf");
  /* 300 */
}

@font-face {
  font-family: Jost-Regular;
  src: url("./FontFamily/Jost-Regular.ttf");
  /* 400 */
}
@font-face {
  font-family: Jost-Medium;
  src: url("./FontFamily/Jost-Medium.ttf");
  /* 500 */
}
@font-face {
  font-family: Jost-SemiBold;
  src: url("./FontFamily/Jost-SemiBold.ttf");
  /* 600 */
}
@font-face {
  font-family: Jost-Bold;
  src: url("./FontFamily/Jost-Bold.ttf");
  /* 700 */
}

body {
  background-color: transparent; /* Your desired background color */
  background-image: 
    linear-gradient(0deg, rgba(232, 233, 234, 0.3) 3px, transparent 1px), /* Horizontal lines with 50% opacity */
    linear-gradient(90deg, rgba(232, 233, 234, 0.3) 3px, transparent 1px); /* Vertical lines with 50% opacity */
  background-size: 125px 110px; /* Size of the grid cells */
}