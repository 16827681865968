
.btn-blue {
  width: 257px;
  height: 45px;
  background-color: #5dade0;
  border: none;
  border-radius: 10px;
  color: #fff;
  position: relative; /* Ensure button is a positioning context for .transition */
  overflow: hidden; /* Prevent overflow of the transition */
  cursor: pointer;
}

.btn-text {
  font-family: Jost-SemiBold;
  font-size: 20px;
  text-align: center;
  padding: 10px;
  position: relative; /* Position relative for better alignment */
  z-index: 1; /* Keep text above the transition */
}

/* .btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
} */

.transition {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 1s;
  background-color: black;
  width: 0;
  height: 45px; /* Start as a point */
  position: absolute;
  left: 50%;
  top: 50%; /* Center vertically */
  transform: translate(-50%, -50%); /* Center horizontally */
  z-index: 0; /* Keep it behind the text */
}

.btn-blue:hover .transition {
  color: #fff;
  width: 200%;  /* Expand to double size for visual effect */
}
