.imgs-ani-mobile {
    animation: zoom-in-zoom-out-mobile 4s ease infinite;
   
  }
  /* .img-width-all{
    width: 29px;
    height: 29px;
  } */
  .circle-item-mobile {
    position: absolute;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin: 7px 0;
    outline: 1px solid black;
    transition: transform 1s ease;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 50px;
}
  @keyframes zoom-in-zoom-out-mobile {
    0% {
      scale: 50%;
    }
    50% {
      scale: 70%;
    }
    100% {
      scale: 50%;
    }
  }
  .width-container{
    overflow-y: scroll;
  }

  @media screen and (max-width: 768px) {
    .third-banner {
      width: 738px;
    }
  }