.footer-bg {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  /* border-top-left-radius: 10px; */
  /* border-top-right-radius: 10px; */
}
.footer-1320 {
  max-width: 1380px;
  min-width: 200px;
  width: 100%;
  margin: 0 auto;
  /* padding: 0 30px; */
}
.footer-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}
.footer-logo {
  padding: 58px 0 59.8px;
  display: flex;
  align-items: center;
}

.email-phone {
  display: flex;
  align-items: center;
  /* gap: 20px; */
  /* font-family: Jost-Regular; */

  color: black;
  padding: 6px 0 0;
}
 a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit color from parent */
}
.semibold {
  font-family: Jost-SemiBold;
  text-decoration: none;
  color: black;
  font-style: none;
}
.social-icons {
  display: flex;
  align-items: center;
  /* gap: 9px; */
  padding: 50px 0 52px;
}
.email {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 20px;
  line-height: 28.9px;
  text-align: center;
}
.phone {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 20px;
  font-family: Jost-Regular;
  line-height: 28.9px;
  text-align: center;
}
.hr-color {
  border: 1px solid rgba(255, 255, 255, 0.5);
  width: 100%;
}
.icons-f {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  outline: 1.7px solid #ffffff;
  color: #ffffff;
  font-size: 20px;
  transition: color 0.3s ease, outline-color 0.3s ease;
}
.icons-f svg path {
  stroke: white;
  transition: stroke 0.3s ease;
}
.copy {
  font-family: Jost-Regular;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  padding: 50px 0;
  color: #fff;
}
.iconss {
  width: 28.57px;
  height: 28.57px;
}
.iconss-fb{
  width:  15.18px;
  height:28.57px ;
}
.hr-rotate-phone{
  border: 1px solid black;
  margin: 0 10px;
  height: 30px;
}
.hr-rotate {
  border: 1px solid black;
  margin: 0 13.5px;
  height: 71.43px;
}
.mail-size{
  width: 28.57px !important;
  height: 28.57px !important;
}
.hr-width{
  border: 1px solid black;
  width: 250px;
  position: relative;
  top: 60.5px;
  left: 4px;
}
.hr-bottom{
  border: 1px solid black;
  width: 250px;
  position: relative;
  bottom: 62.5px;
  left: 4px;
}
.phone svg{
  /* margin: 4px 0 0; */
}
.email svg{
  /* margin: 3px 0 0; */
}
.phone path{
  position: relative;
  /* top: 5px; */
  margin-top:5px !important;
  /* font-size: 24px !important; */
  transform: scale(0.9); /* Increase the size of the path */
  transform-origin: center; 
}
.email path{
  position: relative;
  /* top: 2px; */
  /* font-size: 24px !important; */
  transform: scale(0.9); /* Increase the size of the path */
  transform-origin: center; 
}
.center-foogter{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:  58px 0 58px;
}
@media screen and (max-width: 1024px) {

.footer-flex {
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 0 30px;
  
}
.footer-logo {
  padding: 50px 0 0;
}
.email-phone {
  padding: 30px 0;
}
.social-icons {
  padding: 0 0 50px;
}
.hr-width {
  top: 12.5px;
}
.hr-bottom {
  bottom: 62.5px;
}
.center-foogter {
  padding: 30px 0 0;
}
}
@media screen and (max-width: 768px) {
  .email-phone {
    flex-direction: column;
    font-size: 16px;
    line-height: 23.12px;
    gap: 11px;
  }
  .hr-rotate-phone{
    display: none;
  }
  .email-phone {
    padding: 11px 0 0;
}
.phone {
  font-size: 20px;
  line-height: 23.12px;
}
.center-foogter {
  padding: 30px 0;
}
}
.icon-gradient{
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-gradient svg {
  fill:rgba(11, 102, 195, 1);
  font-size: 30px;
  position: relative;
  top: 2.5px;
}
.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.fb{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: 6.5px;
  top: 0px;
  width: 28.57px;
}

.fb svg{
  font-size: 30px;
}