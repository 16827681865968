.content-1380 {
  max-width: 1380px;
  min-width: 200px;
  width: 100%;
  padding: 0 30px;
  margin: 0 auto;
}
.card-banner-container {
  max-width: 1320px;
  min-width: 200px;
  width: 100%;
  margin: 0 auto;
  height: 549px;
  border-radius: 10px;
  background-image: radial-gradient(
      circle at left bottom,
      rgba(251, 201, 201, 0.2),
      transparent 35%
    ),
    /* Soft blue at left bottom */
      radial-gradient(
        circle at bottom center,
        rgba(251, 201, 201, 0.2),
        transparent 50%
      ),
    /* Soft orange in bottom center */
      radial-gradient(
        circle at right bottom,
        rgba(251, 201, 201, 0.2),
        transparent 35%
      ),
    /* Faint blue at top right */
      radial-gradient(
        circle at top center,
        rgba(112, 205, 255, 0.3),
        transparent 75%
      ),
    radial-gradient(
      circle at left top,
      rgba(251, 201, 201, 0.1),
      transparent 25%
    ),
    radial-gradient(
      circle at right top,
      rgba(251, 201, 201, 0.1),
      transparent 25%
    );
  background-color: rgba(251, 242, 237, 0.5);
}

.display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 28px;
}
.gif-container {
  max-width: 470px;
  min-width: 200px;
  width: 100%;
  height: 519px;
  position: relative;
  background-color: #5dade080;
  border-radius: 10px;
  margin: 15px 0 15px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gif-img {
  position: absolute;
}
.img-of-gif {
  max-width: 500.38px;
  min-width: 200px;
  width: 100%;
  max-height: 490.5px;
  min-height: 200px;
  height: 100%;
}
.content-banners {
  max-width: 779px;
  min-width: 200px;
  width: 100%;
  margin: 72px 28px 72px 0;
}
.title-service-card-banners {
  font-family: Jost-SemiBold;
  font-size: 25px;
  line-height: 35px;
  text-align: left;
}
.desc-service-card-banners {
  font-family: Jost-Regular;
  font-size: 20px;
  line-height: 28.9px;
  text-align: left;
}
.p-5 {
  padding: 5px 0 0;
}
.p-30 {
  padding: 0 0 30px;
}
.points-service-card-banners {
  display: flex;
  gap: 10px;
  padding: 20px 0;
}

.points-font {
  font-family: Jost-Medium;
  font-size: 18px;
  line-height: 26.01px;
  text-align: left;
  /* padding: 0 0 0 20px; */
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.points-font li {
  position: relative;
  padding: 0 0 0 15px;
}

.points-font li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 8px;
  height: 8px;
  background-color: #7dbbe3; /* Your desired color */
  border-radius: 50%; /* Makes the dot round */
}
.btn-flex{
  display: flex;
  gap: 10px;
}
@media screen and (max-width: 1120px) {
  .btn-flex{
    flex-direction: column;
  }
}
@media screen and (max-width: 1024px) {
  .content-1380 {
    padding: unset;
  }
  .display-flex {
    flex-wrap: wrap;
    gap: 25px;
    justify-content: flex-start;
  }
  .card-banner-container {
    height: unset;
  }
  .gif-container {
    max-width: 990px;
    min-width: 200px;
    width: 100%;
    background-color: #5dade080;
    margin: 25px 25px 0 25px;
  }
  .content-banners {
    margin: 0 25px 61px 25px;
  }
  .btn-flex{
    justify-content: center;
    align-items: center;
  }
  .btn-flex{
    flex-direction: row;
  }
}
@media screen and (max-width: 768px) {
  .gif-container {
    width: 100%;
    background-color: #5dade080;
    margin: 10px 10px 0 10px;
    /* height: 300px; */
  }
  .title-service-card-banners {
    font-size: 18px;
    line-height: 26px;
    text-align: left;
  }
  .content-banners {
    margin: 0 10px 25px 10px;
  }
  .desc-service-card-banners {
    font-size: 16px;
    line-height: 23.12px;
    text-align: left;
  }
  .points-font {
    font-family: Jost-Medium;
    font-size: 16px;
    line-height: 23.12px;
  }
  .btn-flex{
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
  }

}
