/* .btn-white {
  width: 257px;
  height: 45px;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  border: 1px solid black;
  position: relative; 
  overflow: hidden;
}

.btn-text-white {
  font-family: Jost-SemiBold;
  font-size: 20px;
  text-align: center;
  padding: 10px;
  position: relative;
  z-index: 1;
  color: black; 
  transition: color 0.5s cubic-bezier(0, 0, 0.2, 1); 
}

.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.transition {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 2s;
  background-color: black;
  width: 0;
  color: #fff;
  height: 45px; 
  position: absolute;
  left: 50%;
  top: 50%; 
  transform: translate(-50%, -50%); 
  z-index: 0; 
}

.btn-white:hover .transition {
  color: #fff;
  width: 200%; 
}

.btn-white:hover .btn-text-white:hover {
  color: #fff; 
} */
.btn-white {
  width: 257px;
  height: 45px;
  background-color: transparent;
  border: none;
  border-radius: 10px;
  border: 1px solid black;
  position: relative; /* Ensure button is a positioning context for .transition */
  overflow: hidden;
  cursor: pointer;
}

.btn-text-white {
  font-family: Jost-SemiBold;
  font-size: 20px;
  text-align: center;
  padding: 10px;
  position: relative;
  z-index: 1;
  color: black; /* Default text color */
  transition: color 0.5s cubic-bezier(0, 0, 0.2, 1); /* Smooth color transition */
}



.transition {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 2s; /* Duration matches text color transition */
  background-color: black;
  width: 0;
  color: #fff;
  height: 45px; /* Start as a point */
  position: absolute;
  left: 50%;
  top: 50%; /* Center vertically */
  transform: translate(-50%, -50%); /* Center horizontally */
  z-index: 0; /* Keep it behind the text */
}

.btn-white:hover .transition {
  width: 200%; /* Expand to double size for visual effect */
}

.btn-white:hover .btn-text-white {
  color: #fff; /* Change text to white on hover */
}

.btn-white .btn-text-white {
  transition: color 2s cubic-bezier(0, 0.2, 0.5, 1); /* Smooth transition for hover out */
}
