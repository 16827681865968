.thank-you-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
  flex-direction: column;
  background-image: radial-gradient(
      circle at left bottom,
      rgba(112, 205, 255, 0.3),
      transparent 50%
    ),
    /* Soft blue at left bottom */
      radial-gradient(
        circle at bottom center,
        rgba(255, 174, 176, 0.2),
        transparent 50%
      ),
    /* Soft orange in bottom center */
      radial-gradient(
        circle at right bottom,
        rgba(216, 255, 227, 0.5),
        transparent 50%
      ),
    /* Soft green at right bottom */
      radial-gradient(
        circle at top right,
        rgba(112, 205, 255, 0.3),
        transparent 60%
      ),
    /* Faint blue at top right */
      radial-gradient(
        circle at top left,
        rgba(216, 255, 227, 0.5),
        transparent 60%
      ); /* Faint orange at top left */

  background-size: cover;
  overflow: hidden;
  background-color: rgba(
    251,
    242,
    237,
    0.5
  );
}

.thank-you-text {
  font-size: 4rem;
  color: #5dade0;
  text-align: center;
  font-family: Jost-Bold;
  animation: typing 2s steps(15), blink 0.5s step-end infinite alternate;
  overflow: hidden;
  white-space: nowrap;
  border-right: 3px solid rgba(255, 255, 255, 0.75);
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 20%;
  }
}

@keyframes sparkle {
  0%,
  100% {
    opacity: 0;
    transform: scale(1) translate(0, 0);
  }
  50% {
    opacity: 1;
    transform: scale(1.5) translate(30px, -30px);
  }
}

.p-text{
    font-size: 18px;
    font-family: Jost-SemiBold;
    line-height: 27px;
}