.contact-container {
  padding: 0 0 100px;
}

.contant-title {
  font-family: Jost-Bold;
  font-size: 40px;
  line-height: 70px;
  text-align: center;
  padding: 100px 0 30px;
}
.contact-form-top {
  max-width: 1380px;
  min-width: 200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
}
.heading {
  font-family: Manrope-ExtraBold;
  font-size: 45px;
  /* line-height: 30px; */
  padding: 100px 0 50px;
  text-align: center;
}

.call-back {
  font-size: 25px;
  font-family: Manrope-Bold;
  line-height: 34.15px;
}

.book-consultation {
  font-size: 18px;
  font-family: Manrope-Regular;
  padding: 8px 0 15px;
}

.contact-text {
  /* padding: 0 0 15px; */
}

.contact-form {
  max-width: 1320px;
  min-width: 200px;
  width: 100%;
  background-image: radial-gradient(
      circle at left bottom,
      rgba(251, 201, 201, 0.2),
      transparent 35%
    ),
    /* Soft blue at left bottom */
      radial-gradient(
        circle at bottom center,
        rgba(251, 201, 201, 0.2),
        transparent 50%
      ),
    /* Soft orange in bottom center */
      radial-gradient(
        circle at right bottom,
        rgba(251, 201, 201, 0.2),
        transparent 35%
      ),
    /* Faint blue at top right */
      radial-gradient(
        circle at top center,
        rgba(112, 205, 255, 0.3),
        transparent 75%
      ),
    radial-gradient(
      circle at left top,
      rgba(251, 201, 201, 0.1),
      transparent 25%
    ),
    radial-gradient(
      circle at right top,
      rgba(251, 201, 201, 0.1),
      transparent 25%
    );
  background-color: rgba(251, 242, 237, 0.5);
  padding: 67px 43px;
  border-radius: 10px;
}
/* .contact-container{
    padding-bottom: 30px;
  } */
.name-contact-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 14px;
}
.input {
  font-family: Jost-Regular;
  font-size: 18px;
  font-weight: 400;
  line-height: 26.01px;
  letter-spacing: -0.01em;
}
.input-field {
  /* margin:0 10px 10px; */
  margin: 0 0 15px 0;
  font-family: Jost-Regular;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  /* height: 49px; */
}

.input-group {
  border: 1px solid #000000b2;
  border-radius: 10px;
  /* padding: 5px; */
}

.input {
  width: 100%;
  border: none;
  padding: 14px 0 12px 12px;
  box-sizing: border-box;
  border-radius: 10px;
  outline: none;
  background-color: transparent;
}

input::placeholder {
  font-family: Jost-Regular;
  font-size: 18px;
  line-height: 26.01px;
  color: #000000b2;
}

textarea.input {
  height: 100px;
  /* padding: 0 0 15px; */
  padding: 11px 0 12px 14px;
  /* resize: none; */
  resize: vertical;
}

textarea::placeholder {
  font-family: Jost-Regular;
  font-size: 18px;
  line-height: 26.01px;
  color: #000000b2;
}

.name-contact {
  max-width: 653px;
  min-width: 200px;
  width: 100%;
}

.contact-button {
  padding: 50px 0 0;
  display: flex;
  justify-content: center;
}

.btn-contact {
  /* display: block; */
  max-width: 1320px;
  min-width: 200px;
  width: 100%;
  background-color: black;
  color: white;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 20px;
  font-family: Manrope-SemiBold;
  position: relative;
  transition: all 1s;
}

.btn-contact span {
  position: relative;
  z-index: 3; /* Ensure text is above the :after element */
}

.btn-contact:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  border-radius: 10px;
  background: #fff;
  transition: all 1s;
  z-index: 2; /* Ensure it's behind the text */
}

.btn-contact:hover {
  color: #000000 !important; /* Font color changes to black */
  /* border: 2px solid #000000;  */
}

.btn-contact:hover:after {
  width: 100%;
}

.input-dropdown {
  width: 100%;
  border: none;
  box-sizing: border-box;
  border-radius: 10px;
  outline: none;
  padding: 14px 0 12px 12px;
  font-family: Jost-Regular;
  font-size: 18px;
  line-height: 26.01px;
  color: #000000b2;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  position: relative;
}
.name-contact-input {
  /* display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 10px; */
  position: absolute;
}
.input-group {
  position: relative;
  background-color: transparent;
}

.input-group::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  pointer-events: none;
}
.arrow-contact path{
  fill: rgba(0, 0, 0, 0.7);
}
.arrow-contact {
  /* font-size: 16px;
    padding-right: 12px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 15px;
    left: 620px; */

  position: absolute;
  top: 53%;
  left: 97%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .input-dropdown {
    width: 100%;
    border: none;
    box-sizing: border-box;
    border-radius: 10px;
    outline: none;
    padding: 9px 0 10px 12px;
    font-family: Manrope-Regular;
    font-size: 18px;
    line-height: 24.59px;
    color: #170521;
  } */

.error-message{
  color: rgb(200, 6, 6);
  padding: 0 14px;
}
 

@media screen and (max-width: 1024px) {
  .contant-title {
    padding: 50px 0 30px;
  }
  .heading {
    padding: 50px 0 30px;
    font-size: 30px;
    /* line-height: 30px; */
  }
  .call-back {
    font-size: 22px;
    line-height: 30px;
  }
  .name-contact-row {
    gap: 14px;
  }
  .arrow-contact {
    right: 5px;
    left: unset;
  }
  .contact-form {
    padding: 67px 25px;
  }
  .contact-form-top {
    padding: unset;
  }
}
@media (max-width: 768px) {
  .contant-title {
    padding: 30px 0 15px;
    font-size: 20px;
    line-height: 28.9px;
  }
  .contact-form {
    padding: 23px 10px;
  }
  .input {
    height: 50px;
  }

  .name-contact-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    gap: unset;
  }
  .name-contact {
    max-width: unset;
    min-width: unset;
    width: 100%;
  }
  .link-tab {
    display: flex;
    flex-direction: column;
    /* gap: 50px; */
    list-style: none;
    /* padding: 0; */
    /* margin: 0; */
    max-width: 584px;
    min-width: 200px;
    width: 100%;
    /* border: 1px solid white; */
    justify-content: space-between;
  }
  .link-tab li {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .heading {
    padding: 50px 0 20px;
    font-size: 20px;
    /* line-height: 30px; */
  }

  .design-description {
    font-size: 16px;
    line-height: 25px;
  }
  .li-text li {
    font-size: 16px;
    line-height: 25px;
  }
  .design-services {
    gap: 20px;
  }
  .heading-graphic {
    font-size: 20px;
    line-height: 30px;
  }
  .graphic-services {
    gap: 5px;
  }
  .book-consultation {
    font-size: 16px;
    font-family: Manrope-Regular;
  }
  .call-back {
    font-size: 16px;
    line-height: 30px;
  }
  .input-dropdown {
    padding: 14px 0 12px 12px;
    height: 50px;
  }

  .contact-container {
    padding-bottom: 50px;
  }
}
.form-underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}

/* Styling for the Select Date button */
.select-date-btn,
.select-time-btn {
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 50px;
  color: #000000b2;
}

/* Arrow styling */
.arrow-contact-date {
  position: absolute;
  top: 53%;
  right: 5px;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Calendar container styling */
.calendar-container {
  position: absolute;
  top: 60px; /* Adjust based on your layout */
  z-index: 1000;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: fit-content;
}

/* Additional styling for the Calendar to match your design */
.react-calendar {
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.react-calendar__navigation button {
  font-family: Jost-Regular;
  font-size: 18px;
  line-height: 26.01px;
  letter-spacing: -0.01em;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  font-family: Jost-Regular !important;
  font-size: 15px !important;
  line-height: 21.68px !important;
  text-align: center;
}
.react-calendar__tile--active {
  background: white !important;
}
.react-calendar__tile--active:hover {
  background: white !important;
}
.react-calendar__tile {
  color: rgba(0, 0, 0, 0.7) ;
}
.react-calendar__tile:hover {
  color: #5dade0 !important;
}
.react-calendar__tile--now {
  background: #fff !important;
  color: #5dade0 !important;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: white !important;
  color: #5dade0;
}
/* Styling for the Select Time button */
.select-time-btn {
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
}

/* Time dropdown container */
.time-dropdown {
  position: absolute;
  top: 60px; /* Adjust based on your layout */
  z-index: 1000;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 115px;
  padding: 14px;
  text-align: center;
  font-family: Jost-Regular;
  font-size: 15px;
  line-height: 21.68px;
  letter-spacing: -0.01em;
  text-align: left;
  cursor: pointer;
}
.time-option {
  padding: 5px 0 0;
  color: rgba(0, 0, 0, 0.7);

}
.time-option:hover{
  color: #5dade0;
}
.selected {
  color: #5dade0;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: unset !important;
  color: #5dade0 !important;
}

.react-calendar__tile:disabled {
  background-color: #fff !important;
  color: #dbd9d9  !important;
}
.react-calendar__month-view__days__day--weekend {
  color: rgba(0, 0, 0, 0.7)!important;
}
.react-calendar__tile--active {
  color: #5dade0 !important;
}
.react-calendar__tile--active abbr{
  background-color: #5dade0;
  color: white;
  /* border: 1px solid #5dade0 !important; */
  border-radius: 50% !important;
  padding: 5px 7px;
}
.react-calendar__navigation button:disabled {
  background-color: white !important;
  color: #dbd9d9 !important; ;
}
abbr[title] {
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none !important;
}
/* Title styling for the dropdown */
.dropdown-title {
  text-align: left !important;
  color: rgba(0, 0, 0, 0.7);
  font-family: Jost-Regular;
  font-size: 18px;
  font-weight: 400;
  line-height: 26.01px;
  text-align: left;
}
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 9999; /* Make sure it's above other content */
  pointer-events: none; /* Prevent interaction with the loader */
}

/* Blur effect for background */
.body-blur {
  filter: blur(5px);
}

/* Loader spinner */
.loader {
  border: 8px solid #f3f3f3; /* Light grey background */
  border-top: 8px solid #3498db; /* Blue color for the spinner */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
