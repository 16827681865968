.bg-white {
  background-color: rgba(255, 255, 255, 0.4);
}
.logo {
  width: 181.84px;
  height: 56px;
}

.top-div {
  /* max-width: 1380px;
  min-width: 200px; */
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  background-image: radial-gradient(
      circle at left bottom,
      rgba(112, 205, 255, 0.3),
      transparent 50%
    ),
    /* Soft blue at left bottom */
      radial-gradient(
        circle at bottom center,
        rgba(255, 174, 176, 0.2),
        transparent 50%
      ),
    /* Soft orange in bottom center */
      radial-gradient(
        circle at right bottom,
        rgba(216, 255, 227, 0.5),
        transparent 50%
      ),
    /* Soft green at right bottom */
      radial-gradient(
        circle at top right,
        rgba(112, 205, 255, 0.3),
        transparent 60%
      ),
    /* Faint blue at top right */
      radial-gradient(
        circle at top left,
        rgba(216, 255, 227, 0.5),
        transparent 60%
      ); /* Faint orange at top left */

  background-size: cover;

  background-color: rgba(
    251,
    242,
    237,
    0.5
  ); /* Light base background color to match the overall soft feel */
}
.main-div {
  max-width: 1380px;
  min-width: 200px;
  width: 100%;
  /* border: 1px solid black; */
  margin: 0 auto;
  padding: 0 30px;
  /* height: 962px; */
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0 0;
}
.grow-container {
  position: relative;
  display: inline-block;
}

.grow-underline {
  position: absolute;
  bottom: -5px; /* Adjust based on the gap between text and the underline */
  left: 0;
  width: 100%;
  height: auto;
}
.potential-underline {
  position: absolute;
  bottom: 4px; /* Adjust based on the gap between text and the underline */
  left: 0;
  width: 100%;
  height: auto;
}
.together-underline {
  position: absolute;
  bottom: -3px; /* Adjust based on the gap between text and the underline */
  left: 0;
  width: 100%;
  height: auto;
}
.top {
  font-size: 45px;
  font-family: Jost-Bold;
  line-height: 70px;
  text-align: center;
  padding: 40px 0;
}
.img-radius {
  display: block;
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 80px;
}

.circle-img {
  position: relative;
  margin: 100px 0 0;
  background-color: rgb(97, 182, 248);
  width: 781px;
  height: 391px;
  border-top-left-radius: 379px;
  border-top-right-radius: 374px;
  overflow: hidden;
  top: 363px;
}

.circle-item {
  position: absolute;
  width: 69px;
  height: 69px;
  border-radius: 50%;
  padding: 5px;
  outline: 1px solid black;
  transition: transform 1s ease;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.img1 {
  top: 240px;
  right: 885px;
  position: relative;
}
.img2 {
  bottom: -10px;
  right: 800px;
  position: relative;
}
.img3 {
  bottom: 175px;
  right: 660px;
  position: relative;
}
.img4 {
  bottom: 240px;
  right: 150px;
  position: relative;
}
.img5 {
  bottom: 190px;
  right: 20px;
  position: relative;
}
.v-icon {
  padding: 0 0 0 3px;
}
.img6 {
  bottom: 105px;
  right: -35px;
  position: relative;
}

/* .img3 {
  bottom: 85px;
  left: 390px;
  position: relative;
}

.img5 {
  bottom: 107px;
  left: 980px;
  position: relative;
}

.img6 {
  bottom: 17px;
  left: 1065px;
  position: relative;
}

.img4 {
  bottom: 153px;
  left: 840px;
  position: relative;
}

.img2 {
  top: 100px;
  left: 255px;
  position: relative;
}

.img1 {
  top: 328px;
  left: 175px;
  position: relative;
} */
.white-opacity {
  background-color: rgba(255, 255, 255, 0.01);
}
.second-title {
  padding: 100px 0 50px;
}

.desc {
  font-size: 20px;
  font-weight: 400;
  padding: 0 30px;
  text-align: center;
}

.third-banner {
  padding: 0 30px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
}

.service3 {
  padding: 30px 0 50px;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}
.cards {
  border-radius: 10px;
  background-color: rgb(242, 241, 241);
  width: 205px;
  height: 109px;
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for color change */
}
.active {
  background-color: #a6cce5;
}
.active::after {
  content: ""; /* Create an empty content */
  position: absolute; /* Position it relative to the tab */
  left: 52%; /* Center it horizontally */
  bottom: -9px; /* Position it below the tab */
  transform: translateX(-50%); /* Center it perfectly */
  border-left: 10px solid transparent; /* Create the left side of the arrow */
  border-right: 10px solid transparent; /* Create the right side of the arrow */
  border-top: 10px solid #a6cce5; /* Create the blue top side of the arrow */
}
.card-txt {
  font-family: Jost-SemiBold;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  padding-bottom: 15px;
  text-decoration: none;
  color: black;
}

.box-content {
  max-width: 1200px;
  min-width: 200px;
  width: 100%;
  height: 500px;
  border-radius: 10px;
  padding: 0 30px;
  margin: 0 auto;
  background: linear-gradient(
    90deg,
    rgba(220, 224, 243, 1) 31%,
    rgba(243, 220, 241, 1) 72%,
    rgba(255, 246, 227, 1) 100%
  );
}

.imgs-ani {
  animation: zoom-in-zoom-out 4s ease infinite;
}
@keyframes zoom-in-zoom-out {
  0% {
    scale: 80%;
  }
  50% {
    scale: 100%;
  }
  100% {
    scale: 80%;
  }
}
.top-emoji-img {
  position: relative;
  /* bottom: 412px; */
  max-width: 781px;
  min-width: 200px;
  width: 100%;
  margin-top: 86px;
}

.display-in-desk {
  display: block;
}

/* <----- Second section css start ----->  */

.Second-Banner {
  max-width: 1380px;
  min-width: 200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
}
.second-banner-title {
  font-family: Jost-Bold;
  font-size: 40px;
  line-height: 70px;
  text-align: center;
  padding: 100px 0 30px;
}
.second-banner-desc-text {
  max-width: 1160px;
  min-width: 200px;
  width: 100%;
  font-family: Jost-Regular;
  font-size: 20px;
  line-height: 28.9px;
  margin: 0 auto;
  text-align: center;
  padding: 0 10px;
}

/* <----- Third section css start ----->  */

.service-container {
}
.service-title {
  font-family: Jost-Bold;
  font-size: 40px;
  line-height: 70px;
  text-align: center;
  padding: 100px 0 30px;
}
.display-service-tab-mobile {
  display: none;
}

.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
@media screen and (max-width: 1295px) and (min-width: 1024px) {
  .card-txt {
    font-size: 18px;
    line-height: 15px;
    /* padding-top: 5px; */
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 1024px) {
  .logo-container {
    padding: 40px 0 0;
  }
  .top {
    padding: 40px 0 30px;
  }
  .service-title {
    padding: 50px 0 30px;
  }
  .card-txt {
    font-size: 16px;
    padding: 0 6px 6px 6px;
  }
  .cards {
    /* width: 112.16px; */
  }
  .top-div {
    padding: 0 25px;
  }
  .Second-Banner {
    padding: 0 25px;
  }
  .second-banner-desc-text {
    padding: 0 0;
  }
  .third-banner {
    padding: 0 25px 25px;
    gap: 9px;
  }
  .second-banner-title {
    padding: 100px 0 30px;
  }
  .lets-build {
    padding: 50px 0 30px;
  }
  .display-in-desk {
    display: none;
  }
  .display-service-tab-mobile {
    display: block;
  }
  .img-radius {
    display: none;
  }
  .circle {
    padding-left: unset;
  }
  .top-emoji-img {
    margin-top: 82px;
  }
}
@media screen and (max-width: 768px) {
  .logo-container {
    padding: 15px 0 0;
  }
  .display-in-desk {
    display: none;
  }
  .display-service-tab-mobile {
    display: block;
  }
  .btn-container {
    flex-direction: column;
    gap: 15px;
  }
  .main-div {
    padding: unset;
  }
  .top-div {
    padding: 0 10px;
  }
  .top {
    font-size: 20px;
    line-height: 28.9px;
    padding: 40px 0 30px;
  }
  .img-radius {
    display: none;
  }
  .circle {
    padding-left: unset;
  }
  .top-emoji-img {
    margin-top: 67px;
  }
  .grow-underline {
    bottom: 0px;
    left: 1px;
    width: 100%;
  }
  .second-banner-title {
    padding: 50px 0 15px;
    font-size: 20px;
    line-height: 28.9px;
  }
  .lets-build {
    padding: 30px 0 15px;
  }
  .potential-underline {
    bottom: 0px;
  }
  .second-banner-desc-text {
    font-size: 16px;
    line-height: 23.12px;
  }
  .Second-Banner {
    padding: 0 10px;
  }
  .service-title {
    font-size: 20px;
    line-height: 28.9px;
    padding: 30px 0 15px;
  }
  .third-banner {
    padding: 0 10px 32px;
    gap: 9px;
    justify-content: unset;
  }
  .cards {
    width: 112.16px !important;
  }
}
