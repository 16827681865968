.top-four-container {
  max-width: 1380px;
  min-width: 200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
}
.why-flourish {
  font-family: Jost-Bold;
  font-size: 40px;
  line-height: 70px;
  text-align: center;
  padding: 100px 0 30px;
}
.img-div-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.img-div-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.black-cirlce {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-size-30 {
  width: 30px;
  height: 30px;
  transform: translate(2%, 0);
}
.title-why-right {
  font-family: Jost-SemiBold;
  font-size: 22px;
  font-weight: 600;
  line-height: 26.01px;
  text-align: right;
  padding: 15px 0 1px;
}
.title-why-left {
  font-family: Jost-SemiBold;
  font-size: 22px;
  font-weight: 600;
  line-height: 26.01px;
  text-align: left;
  padding: 15px 0 1px;
}
.why-desc {
  font-family: Jost-Regular;
  font-size: 20px;
  line-height: 28.9px;
  text-align: justify;
}

/* YourComponent.css */

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  column-gap: 50px;
  row-gap: 30px;
  position: relative;
  padding: 0 89px;
}

.service-item,
.team-item {
  position: relative;
}
.service-item:not(:nth-child(1))::after {
  content: "";
  position: absolute;
  top: calc(100% + 15px);
  right: -50px;
  width: 230%;
  height: 1px;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 25%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0.3) 75%,
    rgba(0, 0, 0, 0) 100%
  );
}
.team-item:not(:nth-child(1))::after {
  content: "";
  position: absolute;
  top: -230px;
  right: calc(100% + 25px);
  width: 1px;
  height: calc(100% - -250px);
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );
}
.imgs-ani-why {
  animation: zoom-in-zoom-out-ani 4s ease infinite;
}
/* .imgs-ani-why {
  animation: zoom-in-zoom-out-ani 4s ease infinite;
  animation-delay: 15s;
} */
@keyframes zoom-in-zoom-out-ani {
  0% {
    scale: 80%;
  }
  50% {
    scale: 100%;
  }
  100% {
    scale: 80%;
  }
}
.hr-line {
  display: none;
}

@media screen and (max-width: 1024px) {
  .why-flourish {
    padding: 50px 0 30px;
  }
  .grid-container {
    padding: unset;
    height: 509px;
    padding: 20px 0;
  }
  .top-four-container {
    padding: 0 25px;
  }
  .service-item:not(:nth-child(1))::after {
    right: -21px;
    width: 230%;
  }
  .team-item:not(:nth-child(1))::after {
    top: -298px;
    height: calc(100% - -340px);
  }
  .hr-line {
    display: none;
  }
}


@media screen and (max-width: 768px) {

  .grid-container {
    display: grid;
    grid-template-columns: unset;
    grid-template-rows: repeat(4, auto);
    padding: unset;
  }
  .grid-container {
    height: unset;
}
.img-div-right {
  justify-content: center;
}
.title-why-right {
  text-align: center;
  font-size: 18px;
  line-height: 26.01px;
}
.why-desc {
  font-size: 16px;
  line-height: 23.12px;
  text-align: center;
}
.top-four-container {
  padding: 0 10px;
}
.img-div-left {
  
  justify-content: center;
}
.title-why-left {
  font-size: 18px;
  line-height: 26.01px;
  text-align: center;
}
.why-flourish {
  font-family: Jost-Bold;
  font-size: 20px;
  line-height: 28.9px;
  padding: 30px 0 15px;
}
.service-item:not(:nth-child(0))::after {
  content: "";
  position: absolute;
  top: calc(100% + 13px);
  right: 25px;
  width: 210px;
  height: 1px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 25%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0.3) 75%, rgba(0, 0, 0, 0) 100%);
}
.service-item:not(:nth-child(2))::after {
  right: 25px;
  width: 210px;
  left: 50%;
  transform: translate(-50%, -180%);
}
.team-item:not(:nth-child(0))::after {
  content: "";
  position: absolute;
  top: -17px;
  right: 25px;
  width: 210px;
  height: 1px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 25%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0.3) 75%, rgba(0, 0, 0, 0) 100%);
}


.hr-line {
  margin: 15px 0 0;
  display: block;
  position: relative;
  width: 100%; 
  max-width: 210px; 
  height: 1px;
  left: 50%;
  transform: translateX(-50%); 
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 25%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0.3) 75%, rgba(0, 0, 0, 0) 100%);
}

.p-line {
  position: relative;
  padding: 0 0 13px;
  text-align: center; 
}

}


